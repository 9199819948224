import { getMapsHelper } from './util/mapsHelper';

export const initLocationMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#location_map'),
				locationElementSelector: '.data_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
						<span class="map_pin">
							<img src="/static/images/icons/map_pin.svg" alt="" />
						</span>
						`
					};
				},
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(10);
			});
		});
};