export const initMobileNav = () => {	
	document.querySelector('#toggle_mobile_nav').addEventListener('click', () => {
		document.querySelector('#toggle_mobile_nav').switchAttribute('state', 'open', 'closed');
		document.querySelector('#nav').switchAttribute('state', 'open', 'closed');
	});
};

// Double Tap functionality
export const initDoubleClick = () => {
	document.querySelectorAll('.d_click').forEach(i => {
		i.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if(!i.hasAttribute('clicked') && window.innerWidth > 750) {
					e.preventDefault();
					i.setAttribute('clicked', true);
				}
			}
		});
	});
};